.intro-meta {
    margin-top: 2%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.highlight {
    color: rgb(144,251,220);
}

.bio {
    color: white;
    width: 80%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    animation: bio-fadeIn 4s;
    display: flex;
    justify-content: space-between
}

.Typist {
    text-align: center;
}

.Typist .Cursor--blinking {
color: rgb(144,251,220);
padding-left: 4px;
}

@media (orientation: landscape) {
    .Typist .Cursor{
        font-size: 4vw;
    }
    .intro {
        color: white;
        font-size: 4vw;
    }
    .bio {
        font-size: 3vh;
    }
}

@media (orientation: portrait) {
    .Typist .Cursor{
        font-size: 4vh;
    }
    .intro {
        color: white;
        font-size: 5vw;
    }
    .bio {
        font-size: 3vw;
    }
}

@keyframes bio-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}