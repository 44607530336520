.card-meta {
    height: 80%;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
}

.card {
    flex: 1 0 21%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: rgb(19,34,60);
    background-color: rgb(19,34,60);
    border-radius: 3%;
    animation: fadeIn 3s;
    min-height: 200px;
    
}

.card:hover {
    transform: translateY(-10px);
}

.card-image {
    height: 60%;
    margin-bottom: 10%;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

@media (orientation: landscape) {
    .card {
        height: 45vh;
    }
    .card-title {
        font-size: 4vw;
        color: white;
        margin: auto;
        margin-top: 2%;
    }
}

@media (orientation: portrait) {
    .card {
        height: 25vh;
    }
    .card-title {
        font-size: 3vh;
        color: white;
        margin: auto;
        margin-top: 2%;
    }
}

.card:hover .card-title {
    color: rgb(144,251,220);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(150px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

a {
    text-decoration: none;
}